// @ts-nocheck
'use strict';
var Swiper = require('swiper');

module.exports = function () {
    var mySwipers = new Swiper.default(".mobileMainCarousel", {
        direction: 'horizontal',
        slidesPerView: 1,
        spaceBetween: 0,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        freeMode: false,
        preloadImages: false,
        watchSlidesVisibility: false,
        watchOverflow: true,
        // autoplay: {
        //     delay: 3000
        // },
        // If we need pagination
        pagination: {
            el: '.mobileMainCarousel .swiper-pagination'
        }
    });

    $('.video').on('play', function () {
        mySwipers.autoplay.stop();
    });

    $('.video').on('ended, pause', function () {
        mySwipers.autoplay.start();
    });

    mySwipers.on('slideChange', function () {
        var slide = mySwipers.slides[mySwipers.activeIndex];
        var currentVideo = ($(slide).find('video'));
        if (currentVideo.length) {
            currentVideo.trigger('play');
        } else {
            currentVideo.trigger('pause');
        }
    });

    var mySwiperss = new Swiper.default(".offers-home-section", {
        slidesPerView: 1,
        spaceBetween: 12,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        preloadImages: false,
        // watchSlidesVisibility: false,
        // effect: 'fade',
        // watchOverflow: true,
        watchOverflow: false,
        autoplay: {
            delay: 3500,
            disableOnInteraction: false
        },
        pagination: {
            el: '.offers-home-section .swiper-pagination'
        }
    });
};
